import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import BanksComponent from '../../Components/BanksComponent'
import HeaderComponent from '../../Components/HeaderComponent'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    height: '100%',
  },
}), { name: 'HomePage' })

function HomePage(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <HeaderComponent />
      {/* <BanksComponent /> */}
    </div>
  )
}

export default HomePage
