/* eslint-disable max-len */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Divider } from '@material-ui/core'
import ArticleCard from './ArticleCard'
import articles from '../../lib/articles'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 60,
    maxHeight: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    maxWidth: 1522,
  },
  header: {
    paddingLeft: 10,
    fontSize: 60,
    fontWeight: 600,
    letterSpacing: -1,
    color: '#788A8A',
    minWidth: 450,
  },
  disclaimer: {
    maxWidth: '40%',
    fontSize: 19,
    color: 'rgb(73, 73, 73)',
    letterSpacing: 1,
    marginBottom: 20,
    minWidth: 400,
  },
  subHeader: {
    paddingLeft: 10,
    fontSize: 40,
    fontWeight: 600,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 28,
  },
}), { name: 'ArticlesPage' })

function ArticlesPage(props) {
  const classes = useStyles(props)

  return (
    <Container className={classes.root}>
      <h1 className={classes.header}>
        Everybyte Blog
      </h1>
      <div className={classes.disclaimer}>
        Still not sure where to start? Let us help you get started.
        Read our latest stories, or explore the topics you’re most interested in.
      </div>
      <Divider />
      <h1 className={classes.subHeader}>
        Latest posts
      </h1>
      <div className={classes.cardsContainer}>
        {articles.map(({
          id,
          image,
          readTime,
          date,
          header,
          content,
          chipContent,
        }) => (
          <ArticleCard
            key={id}
            id={id}
            image={image}
            readTime={readTime}
            date={date}
            header={header}
            content={content}
            chipContent={chipContent}
          />
        ))}
      </div>
    </Container>
  )
}

export default ArticlesPage
