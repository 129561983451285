/* eslint-disable max-len */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  Container,
  Button,
  Snackbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    // maxHeight: '100%',
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  formContainer: {
    display: 'flex',
    // justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  formControl: {
    display: 'flex',
    // flex: 1,
  },
  inputContainer: {
    // display: 'flex',
    // flex: 1,
    width: '40%',
    alignSelf: 'center',
    textAlign: 'center',
    // justifyContent: 'space-around',
  },
  submitBtn: {
    backgroundColor: 'black',
    color: 'white',
    padding: 10,
    width: '80%',
    borderRadius: 10,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
}), { name: 'ContactUsFormComponent' })

const ContactUsFormComponent = (props) => {
  const classes = useStyles(props)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)

  const handleOpenSnackbar = () => setOpenSnackbar(true)

  const handleCloseSnackbar = () => setOpenSnackbar(false)

  const Alert = (alertProps) => <MuiAlert elevation={6} variant="filled" {...alertProps} />

  const onSubmitForm = (e) => {
    e.preventDefault()

    handleOpenSnackbar()
  }

  return (
    <div className={classes.root}>
      <form className={classes.formContainer}>
        <Container className={classes.inputContainer}>
          <FormControl className={classes.formControl} style={{ paddingRight: 5 }}>
            <InputLabel htmlFor="firstName">First name*</InputLabel>
            <Input required id="firstName" aria-describedby="my-helper-text" />
          </FormControl>
        </Container>
        <Container className={classes.inputContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="lastName">Last Name*</InputLabel>
            <Input required id="lastName" aria-describedby="my-helper-text" />
          </FormControl>
        </Container>
        <Container className={classes.inputContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="firstName">Email*</InputLabel>
            <Input required id="firstName" aria-describedby="my-helper-text" />
          </FormControl>
        </Container>
        <Container className={classes.inputContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="lastName">Phone*</InputLabel>
            <Input required id="lastName" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">Please complete this required field</FormHelperText>
          </FormControl>
        </Container>
        <Container className={classes.inputContainer} style={{ paddingTop: 5 }}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="lastName">How can we help you?*</InputLabel>
            <Select required labelId="label" id="select">
              <MenuItem value="1">I want to talk to sales</MenuItem>
              <MenuItem value="2">I want to become a partner</MenuItem>
              <MenuItem value="3">Something else</MenuItem>
            </Select>
            <FormHelperText id="my-helper-text">Please select an option from the dropdown menu.</FormHelperText>
          </FormControl>
        </Container>
        <Container className={classes.inputContainer} style={{ paddingTop: 15 }}>
          <Button type="submit" onSubmit={onSubmitForm} className={classes.submitBtn} variant="contained">Submit</Button>
        </Container>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={1500} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          Sent successfully!
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ContactUsFormComponent
