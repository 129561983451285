import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ContactUsFormComponent from '../../Components/ContactUsFormComponent'

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}), { name: 'PricingPage' })

function PricingPage(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div>
        <h1 style={{ fontSize: 52 }}>
          Contact us
        </h1>
      </div>
      <div>
        <h3>
          Interested to talk more about how we can build the future of
          <br />
          financial services? We’re happy to hear from you.
          <br />
          Fill in the form below and we’ll be in touch soon.
        </h3>
      </div>
      <ContactUsFormComponent />
    </div>
  )
}

export default PricingPage
