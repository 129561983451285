import React from 'react'
import Typewriter from 'typewriter-effect'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import blackBackground from '../assets/images/black-background.jpg'

const IS_MOBILE = window.innerWidth < 550

const useStyles = makeStyles(() => ({
  titleContainer: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${blackBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '32px',
  },
  title: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: 'white',
    margin: 'auto',
    maxWidth: 450,
    fontSize: '3rem',
    lineHeight: '3rem',
    letterSpacing: '-0.0375rem',
    textAlign: 'center',
  },
  subTitle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 300,
    color: 'white',
    maxWidth: '111.111112vw',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.0375rem',
    textAlign: 'center',
    opacity: 0,
    animationFillMode: 'forwards',
    animationDelay: '6s',
    animation: '$fadeIn 1000ms ease-in-out'
  },
  startBtn: {
    display: 'flex',
    backgroundColor: 'orange',
    width: '15%',
    alignSelf: 'center',
    padding: '10px',
    marginBottom: '20px',
    transition: '250ms',
    '&:hover': {
      letterSpacing: 1.5,
      boxShadow: '0 5px 15px 7px rgb(255 255 255 / 20%)',
      transform: 'translateY(-3px)',
      backgroundColor: '#ff8d00',
    },
    opacity: 0,
    animationFillMode: 'forwards',
    animationDelay: '6s',
    minWidth: 115,
    fontWeight: 'bold',
    fontFamily: 'Inter',
    animation: '$fadeIn 1500ms ease-in-out'
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(5rem)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
}), { name: 'HeaderComponent' })

const HeaderComponent = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.titleContainer}>
      <h1 className={classes.title}>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .changeDelay(75)
              .typeString('Everybyte')
              .pauseFor(1000)
              .deleteAll()
              .typeString('The easiest way to verify identities')
              .start()
          }}
        />
      </h1>
      {!IS_MOBILE && (
        <div>
          <h3 className={classes.subTitle}>
            Everybyte lets you programmatically confirm the identity of
            <br />
            global users using their bank account (patent pending)
            <br />
            so you can prevent attacks from fraudsters while
            <br />
            minimizing friction for legitimate customers.
          </h3>
        </div>
      )}
      <Button
        className={classes.startBtn}
        variant="contained"
        onClick={() => {
          window.location.replace('https://demo.everybyte.io/')
        }}
      >
        Launch demo
      </Button>
    </div>
  )
}

export default HeaderComponent
