/* eslint-disable react/no-danger */
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import classNames from 'classnames'
import articles from '../../lib/articles'

const IS_MOBILE = window.innerWidth < 550

const useStyles = makeStyles(() => ({
  root: {
    animation: '$fade-in 0.6s ease-in',
  },
  imageContainer: {
    width: IS_MOBILE ? '100%' : '50%',
  },
  infoContainer: {
    display: 'flex',
    color: 'rgb(113, 113, 113)',
    justifyContent: 'space-between',
    width: IS_MOBILE ? '80%' : '30%',
    alignItems: 'center',
    marginBottom: 30,
  },
  chip: {
    backgroundColor: 'rgb(230, 245, 248)',
    padding: 10,
    letterSpacing: 1,
    fontSize: 12,
    borderRadius: 26,
  },
  content: {
    fontSize: 18,
    color: 'rgb(73, 73, 73)',
    letterSpacing: 1,
    marginBottom: 30,
    marginTop: 30,
    maxWidth: IS_MOBILE ? '100%' : '50%',
  },
  bold: {
    fontWeight: 'bold',
  },
  header: {
    fontSize: 60,
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}), { name: 'SingleArticlePage' })

function SingleArticlePage(props) {
  const classes = useStyles(props)
  const { params: { id: articleId } } = useRouteMatch('/articles/:id')

  const article = articles.find(({ id }) => articleId === id)

  const {
    image,
    readTime,
    date,
    header,
    content,
    chipContent,
    paragraph,
  } = article || {}

  return (
    <>
      {article && (
        <Container className={classes.root}>
          <h1 className={classes.header}>
            {header}
          </h1>
          <div className={classes.infoContainer}>
            <div className={classes.chip}>
              {chipContent}
            </div>
            <div>{date}</div>
            <div> · </div>
            <div>{readTime}</div>
          </div>
          <div className={classNames(classes.content, classes.bold)}>
            {content}
          </div>
          <div className={classes.imageContainer}>
            <img style={{ width: '100%' }} className={classes.image} src={image} alt="logo" />
          </div>
          <div className={classes.content} dangerouslySetInnerHTML={{ __html: paragraph }} />
        </Container>
      )}
    </>
  )
}

export default SingleArticlePage
