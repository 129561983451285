import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    animation: '$fade-in 0.6s ease-in',
    width: 400,
    boxShadow: 'rgb(0 0 0 / 4%) -1px 20px 25px',
    background: 'white',
    cursor: 'pointer',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 9%) 0px 4px 12px 4px',
    },
  },
  restContainer: {
    padding: 15,
  },
  imageContainer: {
    width: 400,
  },
  infoContainer: {
    display: 'flex',
    color: 'rgb(113, 113, 113)',
    justifyContent: 'space-between',
    width: '50%'
  },
  chip: {
    backgroundColor: 'rgb(230, 245, 248)',
    padding: 10,
    letterSpacing: 1,
    fontSize: 12,
    borderRadius: 26,
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  content: {
    fontSize: 16,
    color: 'rgb(73, 73, 73)',
    letterSpacing: 1,
    marginBottom: 20,
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}), { name: 'ArticleCard' })

function ArticleCard(props) {
  const classes = useStyles(props)
  const history = useHistory()

  const {
    image,
    readTime,
    date,
    header,
    content,
    chipContent,
    id,
  } = props

  return (
    <div className={classes.root} onClick={() => history.push(`/articles/${id}`)}>
      <div className={classes.imageContainer}>
        <img style={{ width: '100%' }} className={classes.image} src={image} alt="logo" />
      </div>
      <div className={classes.restContainer}>
        <div className={classes.infoContainer}>
          <div>{date}</div>
          <div> · </div>
          <div>{readTime}</div>
        </div>
        <h2>
          {header}
        </h2>
        <div className={classes.content}>
          {content}
        </div>
        <div className={classes.chipContainer}>
          <div className={classes.chip}>
            {chipContent}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
