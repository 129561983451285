import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import computerAndPhoneImage from '../../assets/images/feature.png'
import logo from '../../assets/images/logo-black-long.png'

const useStyles = makeStyles(() => ({
  hero: {
    margin: 'auto',
    marginTop: 100,
  },
  heroWrapper: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: '88.2rem',
  },
  heroContent: {
    width: '50%',
    marginTop: 100,
    animation: '$fade-in 0.6s ease-in',
  },
  paragraph: {
    maxWidth: '90%',
    margin: 10,
    fontSize: 18,
  },
  headerOne: {
    fontSize: 39,
    fontWeight: 800,
  },
  heroImage: {
    width: '50%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}), { name: 'AboutUsPage' })

export default function AboutUsPage() {
  const classes = useStyles()

  return (
    <>
      <div className={classes.hero}>
        <div className={classes.container}>
          <div className={classes.heroWrapper}>
            <div className={classes.heroContent}>
              <h1 className={classes.headerOne}>The easiest way to verify identities.</h1>
              <p className={classes.paragraph}>
                As more business is conducted online, a seamless digital identity verification
                process is a critical component to ensure trust with customers.
                Everybyte believes in building that trust via a smooth user experience,
                with privacy and security as foundational elements.
                Knowing your customer (KYC) is more important than ever,
                and an easy-to-implement and easier-to-use identity verification tool like Everybyte
                provides that comfort for all parties to ensure safe transactions.
              </p>
            </div>
            <div className={classes.heroImage}>
              <img className={classes.image} src={computerAndPhoneImage} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.hero}>
        <div className={classes.container}>
          <div className={classes.heroWrapper}>
            <div className={classes.heroImage}>
              <img style={{ width: '100%' }} className={classes.image} src={logo} alt="logo" />
            </div>
            <div className={classes.heroContent}>
              <h1 className={classes.headerOne}>Who we are.</h1>
              <p className={classes.paragraph}>
                Everybyte is designed to make user verification/authentication processes seamless and complete.
                Our vision is to fight fraud and deliver actionable KYC insights, plain and simple.
                We pride ourselves on the stringent security methods we baked into Everybyte to ensure a user
                {'\''}
                s private information is
                only accessible by those that need to know. We are trusted by many institutions
                such as insurance companies, credit unions, financial institutions, and internet/digital banks.
                Our product has been designed to cater to the strictest regulatory requirements so compliance and
                security works for our customers, not against them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
