/* eslint-disable max-len */
import { uniqueId } from 'lodash'
import wireImage from '../assets/images/wire.png'
import identityImage from '../assets/images/identity.png'
import cyberImage from '../assets/images/cyber.png'
import cyber2Image from '../assets/images/cyber2.png'
import everybyteImage from '../assets/images/everybyte-article.png'
import demoImage from '../assets/images/demo-image.webp'

export default [
  {
    id: uniqueId(),
    image: everybyteImage,
    readTime: '3 min read',
    date: '2021-09-01',
    header: 'Everybyte, who are we?',
    content: 'Everybyte is designed to make user verification/authentication processes seamless and complete. Our vision is to fight fraud and deliver actionable KYC insights, plain and simple.',
    chipContent: 'EVERYBYTE NEWS',
    paragraph: `Everybyte – identity verification made easy.
    <br /><br />
    The digital age has seen a transition from self-identification to identity verification.
    <br /><br />
    Companies and organizations must earn customer&#39;s trust – online.
    <br /><br />
    The overwhelming majority of online identity verification of the occurs with people we do not know,
    which is why organizations and companies must place a system which will ensure the individual is
    genuine rather than an imposter. A user and customer ID guarantees that the user logging
    on to online services is the real deal. Verification plays an important role in safeguarding
    from fraud, with the aim of providing security and a positive customer experience. In the
    context of online transactions, users identify themselves by providing a name, address, email
    address or phone number. When an individual purchases a product online, he does so by
    typing in a credit card number and billing address. So long as the individual possesses
    information on the individual associated to credit card, the process proceeds. Given this
    state of affairs, the individual can declare his identity without sufficient verification. For
    most online services, the identification, in and of itself, is often insufficient. Via verification
    we proceed to a secured and protected stage where we verify that you are who you say you
    are.
    <br /><br />
    Identity verification requires a more stringent entry filter, and safer standards. Companies
    and organizations which fail to adopt a verification policy risk receiving false information.
    The power of the verification system is dependent on several integrated factors – the more
    databases it is connected to, the stronger it is. Our system supports many databases and
    cannot be hacked or copied. The market value of identity management is projected to pass
    the 100 billion mark next year.
    <br /><br />
    Our method is based on a proprietary international patent, which we use to compare user
    identity to the financial activity which only the user knows. This information is dynamic and
    changes day to day in accordance to the activities he performs. Accordingly, as time goes by,
    our identity verification becomes more certain. In fact, we don’t just recognize the user in
    the here and now (which enables identity thieves to disguise themselves as the user and
    flee).
    <br /><br />
    The solution is very simple to integrate in every internet site, requiring no special
    technological knowledge.`,
  },
  {
    id: uniqueId(),
    image: identityImage,
    readTime: '5 min read',
    date: '2021-08-09',
    header: 'Identity Theft',
    content: 'Identity theft. It\'s a plague. Possibly the fastest growing non-violent crime in the U.S, it can also be hard to detect. It can be weeks, months, even years for the victims to become aware that there identity has been stolen.',
    chipContent: 'IDENTITY SOLUTIONS',
    paragraph: `Identity theft. It's a plague. Possibly the fastest growing non-violent crime in the U.S, it can
    also be hard to detect. It can be weeks, months, even years for the victims to become aware
    that there identity has been stolen. Indeed, the FDC assess that a stolen identity is used 30
    times, on the average, before the victim becomes aware.
    <br /><br />
    And it is so goddamn easy. It's no longer clickbait traps on gambling sites and the like
    demanding your credit card details. Anyone with a social security number can be a target.
    Up to 10 percent of U.S citizens become victims of identity fraud – annually. Nor is getting
    burned once enough to immunize you from further depredations – 21% of these people are
    repeat victims.
    <br /><br />
    It has never been more important to take computer security seriously. Making time to
    monitor credit is no longer an optional add-on – it's a prerequisite for any business which
    wishes to remain solvent.
    <br /><br />
    Nor is there any room for despair. Yes, scams are getting more sophisticated. But so are the
    countermeasures. And even the most sophisticated scam can be defeated by the least
    sophisticated countermeasure of all – diligence, vigilance and thoroughness.
    Is the investment of time worth it? Well, let us consider what types of identity theft are most
    common – and you can weigh for yourself whether the time and money required to deploy
    appropriate countermeasures balance out the harm they prevent.
    <br /><br />
    Driver's ID theft. What can a criminal do with a driver ID? Well, they can use it to
    apply for loans, open bank accounts and checking accounts; buy all sorts of
    expensive stuff (cars, boats, jewelry – even homes!), charging the costs to you… and
    also find out where you live.
    <br /><br />
    Social security ID theft. Social security IDs reap the perps all of the benefits of
    driver's license ID theft – but also social security benefits. You know, the benefits
    you slaved away for decades to get: HUD, welfare, medical care, dental care…
    <br /><br />
    Medical ID theft
    Ever have the misfortune of visiting a hospital for an absolutely necessary medical
    procedure and then get hit with a colossal bill with inexplicable items? Well, how
    would you like to receive an even more inflated same bill…. But with none of the
    aforementioned benefits? No? didn’t think so. Read on, then.
    <br /><br />
    For those who are business owners, financial ID theft looms most large. A hacker
    who infiltrates your organization's systems can wreak havoc on your business within
    short order, ruining not only your bank account, but also decade-long relationships
    with clients and suppliers.
    <br /><br />
    Here's the good news: countermeasures exist. They work. And there is nothing stopping you
    from adopting them in your own business.`,
  },
  {
    id: uniqueId(),
    image: wireImage,
    readTime: '3 min read',
    date: '2021-06-23',
    header: 'Escrow Wire Fraud',
    content: 'You get an email. That is how it starts. The mail is supposedly sent by an escrow company and instructs you where to wire your escrow funds. The con artist prepares a website that matches the appearance of the company you are used to working with, to lull any suspicions.',
    chipContent: 'IDENTITY SOLUTIONS',
    paragraph: `You get an email. That is how it starts. The mail is supposedly sent by an escrow company
    and instructs you where to wire your escrow funds. The con artist prepares a website that
    matches the appearance of the company you are used to working with, to lull any
    suspicions. Spoofing tactics are used to give phone numbers, websites and email addresses a
    familiar appearance – but there is always one character off in the address.
    <br /><br />
    Usually, victims automatically wire the funds over, and are none the wiser until
    communications with the real escrow company reveal that the funds were never received –
    or asked for! By then the fraudsters have already siphoned the funds into an offshore
    account and that is all she wrote. There are, of course, options to get your money back, from
    the bank if note from the scam artists. But it is ever so much easier to implement
    countermeasures to begin with.
    <br /><br />
    The first, elementary, step to protect yourself from this type of fraud is to review the original
    documents you received from the lending institution and call the listed contacts there to
    verify any wiring instructions you might receive. Never, under any circumstances, click on
    email or text links, let alone send money online, before you double check any wire
    instructions with an actual human on the other side of a phone line specified in the original
    paper documentation you received.
    <br /><br />
    Another warning sign to keep an eye out for is any email asking for authorization to change
    wiring instructions you had previously received. Once again, verification versus a human
    agent before and after you wire funds is the best proof against such frauds.`,
  },
  {
    id: uniqueId(),
    image: cyberImage,
    readTime: '4 min read',
    date: '2021-02-12',
    header: 'More Cyber Security Tips For Working From Home',
    content: 'More Americans have worked this year from home than ever before, and many of them have no intention of going back to their offices full-time, even once the pandemic received.',
    chipContent: 'EVERYBYTE TIPS',
    paragraph: `More Americans have worked this year from home than ever before, and many of them
    have no intention of going back to their offices full-time, even once the pandemic received.
    It is unclear, however, just how many of them are appraised of how to work safely from
    home. After all, around 31% of businesses have endured some sort of cyber attack. As a
    business owner and/or employer it is, of course, your duty to ensure the safety of your
    business and work data by implementing the following working from home cybersecurity
    tips.
    <br /><br />
    First and most obvious, is to avoid public wi-fi. This may not be as obvious as it may
    seem. The fact is that while people like to work from home, they also like making a
    change of scenery in cafes. This is a pleasant way of working, what with the food-to-
    order, changing scenery and pretty young things. It is also a very dangerous way to
    work. It gives hackers on the same public Wi-Fi network a chance to target your
    computer.
    <br /><br />
    So what is an employer permitting employees to work from home (which perforce
    also means cafes) to do? Require your employees to use encryption and/or personal
    hot spots.
    <br /><br />
    How many of you employees use anagrams of their family names, pet names, or
    some easily predictable number pattern on their computers? Hackers love those
    employees – because they give them a way in, not just to the slack employee's
    computer, but to your entire organizational network.
    What to do? Install password requirements that are long, multi character, require
    upper case and lower-case characters, numbers as well as characters – and also two
    stage authentication. Impose sanctions on those using the same passwords in
    different systems.
    <br /><br />
    Don’t leave security program installation to your employees. Get a license for a
    good, up-to-date security program, and ensure all of the laptops used by your
    employees to work are properly secured.`,
  },
  {
    id: uniqueId(),
    image: demoImage,
    readTime: '3 min read',
    date: '2021-01-13',
    header: 'Escrow Wire Fraud',
    content: 'You get an email. That is how it starts. The mail is supposedly sent by an escrow company and instructs you where to wire your escrow funds. The con artist prepares a website that matches the appearance of the company you are used to working with, to lull any suspicions.',
    chipContent: 'IDENTITY SOLUTIONS',
    paragraph: `You get an email. That is how it starts. The mail is supposedly sent by an escrow company
    and instructs you where to wire your escrow funds. The con artist prepares a website that
    matches the appearance of the company you are used to working with, to lull any
    suspicions. Spoofing tactics are used to give phone numbers, websites and email addresses a
    familiar appearance – but there is always one character off in the address.
    <br /><br />
    Usually, victims automatically wire the funds over, and are none the wiser until
    communications with the real escrow company reveal that the funds were never received –
    or asked for! By then the fraudsters have already siphoned the funds into an offshore
    account and that is all she wrote. There are, of course, options to get your money back, from
    the bank if note from the scam artists. But it is ever so much easier to implement
    countermeasures to begin with.
    <br /><br />
    The first, elementary, step to protect yourself from this type of fraud is to review the original
    documents you received from the lending institution and call the listed contacts there to
    verify any wiring instructions you might receive. Never, under any circumstances, click on
    email or text links, let alone send money online, before you double check any wire
    instructions with an actual human on the other side of a phone line specified in the original
    paper documentation you received.
    <br /><br />
    Another warning sign to keep an eye out for is any email asking for authorization to change
    wiring instructions you had previously received. Once again, verification versus a human
    agent before and after you wire funds is the best proof against such frauds.`,
  },
  {
    id: uniqueId(),
    image: cyber2Image,
    readTime: '4 min read',
    date: '2020-12-12',
    header: 'Cyber Security Tips For Working From Home',
    content: 'What is account takeover fraud? Account takeover fraud is simply put the takeover of a legitimate account by a hacker. Take a look at our tips so you wont get fooled',
    chipContent: 'EVERYBYTE TIPS',
    paragraph: `Account takeover fraud
    What is account takeover fraud?
    Account takeover fraud is simply put the takeover of a legitimate account by a hacker.
    Once the account is taken over, various types of accounts can ensue including but not
    limited to:
    <br /><br />
    Internal Phishing: emails supposedly sent by a fellow employee within utilizing a
    compromised corporate account.
    <br /><br />
    Supply-chain phishing: when a hacker takes over a legitimate account, he can
    leverage it to defraud their customers and partners. After all, most business is
    conducted by email nowadays.
    <br /><br />
    BEC attacks. Account takeover represents the ultimate impersonation assault. The
    hacker essentially uses the email account to become the individual to whom the
    account belongs, bypassing quite a few authentication controls.
    <br /><br />
    Data theft- once access is gained to a given mailbox, the hackers can do more than
    read over your mail. They also have access contracts, calendar event and even bank
    account information and other sensitive files.
    <br /><br />
    Financial fraud – finally, once control of bank accounts are secured by the hacker,
    then fraudulent purchases and transfers can be used to siphon off funds directly.
    How does the hacker gain access to his victim's accounts? One way is through simple brute
    force. Automated scripts try and retry various username/password combinations until they
    hit the jackpot.
    <br /><br />
    There are, however, more sophisticated methods. In the breach replay attack the hacker
    makes use of the bad tendencies of many people to use identical or similar passwords for
    different accounts. If one such password is compromised, then any account using the same
    password and/or username is endangered. Old-style credential Phishing is still a thing. If
    multi factor authentication controls are not in place, then leaked credentials can easily
    breach your account. Finally, there are trojan horses and various other malware's such as
    key loggers. These can expose your credentials and give hackers control of your accounts.`,
  },
]
