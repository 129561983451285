import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect, Route, Switch } from 'react-router-dom'

import HomePage from './Pages/HomePage/HomePage'
import PricingPage from './Pages/ContactUsPage/ContactUsPage'
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage'
import ArticlesPage from './Pages/ArticlesPage/ArticlesPage'
import SingleArticlePage from './Pages/SingleArticlePage/SingleArticlePage'

const useStyles = makeStyles(() => ({
  root: {
    // display: 'flex',
    // flex: 1,
    width: '100%',
    // justifyContent: 'center',
    height: '100%',
  },
}), { name: 'MainRouter' })

export default function MainRouter() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/contact">
          <PricingPage />
        </Route>
        <Route exact path="/aboutus">
          <AboutUsPage />
        </Route>
        <Route exact path="/articles">
          <ArticlesPage />
        </Route>
        <Route exact path="/articles/:id">
          <SingleArticlePage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}
