import Immutable from 'seamless-immutable'
import { handleActions } from 'redux-actions'
import { appTypes } from '../actions/types'

const initialState = Immutable({
  example: 'hello',
})

export default handleActions({
  [appTypes.helloRedux]: (state, { value }) => state.set('example', value),
}, initialState)
