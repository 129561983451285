import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core'
import logo from '../assets/images/logo.png'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: 'black',
    position: 'static',
    width: '100%',
    padding: '0px 15px',
    margin: '0px auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '56px',
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '56px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '40px',
    heigh: '40px',
    padding: '0px',
  },
  buttonsContainer: {
    display: 'flex',
  },
  menuBar: {
    backgroundColor: 'white',
    bottom: '0',
    content: '',
    display: 'block',
    height: '4px',
    position: 'relative',
    transitionDuration: '200ms',
    transitionProperty: 'width',
    transitionTimingFunction: 'ease',
    width: '0',
  },
  menuItem: {
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 30px 0 30px',
    '&:hover': {
      '& div': {
        width: '100%',
      }
    },
  },
}), { name: 'MainRouter' })

const AppBarComponent = () => {
  const classes = useStyles()

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolBar}>
        <div style={{ fontFamily: 'Inter', display: 'flex', flexDirection: 'row' }}>
          <img src={logo} alt="logo" color="white" className={classes.logo} />
          <Typography variant="h6" style={{ fontFamily: 'Inter', alignSelf: 'center', paddingLeft: 5 }}>
            Everybyte
          </Typography>
        </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.menuItem}>
            <Button color="inherit" href="/" style={{ fontFamily: 'Inter', fontSize: 20 }}>
              Home
            </Button>
            <div className={classes.menuBar} />
          </div>
          <div className={classes.menuItem}>
            <Button color="inherit" href="/articles" style={{ fontFamily: 'Inter', fontSize: 20 }}>
              Articles
            </Button>
            <div className={classes.menuBar} />
          </div>
          <div className={classes.menuItem}>
            <Button color="inherit" href="/contact" style={{ fontFamily: 'Inter', fontSize: 20 }}>
              Contact Us
            </Button>
            <div className={classes.menuBar} />
          </div>
          <div className={classes.menuItem}>
            <Button color="inherit" href="/aboutus" style={{ fontFamily: 'Inter', fontSize: 20 }}>
              About us
            </Button>
            <div className={classes.menuBar} />
          </div>
        </div>
        <div className={classes.menuItem}>
          <Button color="inherit" href="/aboutus" style={{ fontFamily: 'Inter', fontSize: 20 }}>
            Login
          </Button>
          <div className={classes.menuBar} />
        </div>
      </Toolbar>
    </AppBar>

  )
}

export default AppBarComponent
